import Page from "src/components/Page";
import MembersList from "src/components/Members";

function Members() {
  return (
  <Page
    title="Members | Smart-CMS"
  >
     <MembersList />
  </Page>
  )
}

export default Members;