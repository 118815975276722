import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { TransactionList } from "../transactions.hook";
import { transactionsListColumns } from "./TransactionsList.constants";
import { useNavigate } from "react-router-dom";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { useState, useEffect, useMemo } from "react";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { queryKeys } from "src/react-query/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchInfinite } from "src/react-query/fetchWithError";
import { InfiniteScroll} from "react-infinite-scroll-component";
import {
  CircularProgress,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip
} from "@mui/material";
import { downloadExcel } from "src/utils/downloadExcel";
import {
  getAllExistedTeam,
  transactionExcelDownload,
} from "src/react-query/endPoints";
import axios from "../../../util/axios";
import TransactionPayoutUpdModal from "./TransactionPayoutUpdModal";
import { StyledSpeedDial } from "src/common/components/StyledSpeedDial";
import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
import { useIsGPUser } from "src/hooks/useIsGPUser.hook";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "src/hooks/useAuth.hook";

export const TransactionsList = () => {
  const navigate = useNavigate();
  const { teamId } = useSelectedTeam();

  const isReadOnly = useAccess(FEATURE.TRANSACTION, "R");

  //CHECKS FOR THE PAYOUTS DETAILS MODAL
  const isUserFromGP = useIsGPUser();
  const hasTransactionReadAccess = useAccess(FEATURE.TRANSACTION, "V");
  // const hasPayoutReadAccess = useAccess(FEATURE.FINANCE, "V");

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [openPayoutsDetails, setOpenPayoutsDetails] = useState(false);

  // const { data, isFetching } = useTransactions({ teamId });

  const initialColumns = transactionsListColumns(isReadOnly);
  const finalTableColumns = initialColumns.filter(
    ({ field }) => field !== "status" && field !== "notes"
  );
  const [tableData, setTableData] = useState([]);
  const { user } = useAuth();
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [allTeamsData, setAllTeamsData] = useState();
  const [dummyTeamData, setDummyTeamData] = useState();
  const [selectedGPTeam, setSelectedGPTeam] = useState([]);
  const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
  const companyType = useMemo(() => {
    return user?.company?.companyType;
  }, [user]);

  const { data, isFetching } = TransactionList({
    teamId,
    page,
    pageSize,
    filterteam: selectedTeam,
    filterGPTeam: selectedGPTeam,
    companyType,
  });
  const rowOrCellClickRedirect = ({ id }) => {
    // if(isReadOnly) return;
    navigate(`/transactions/${id}`);
  };

  useEffect(() => {
    if (user?.company?.id === teamId) {
      setAllTeamsData(dummyTeamData);
    } else {
      const team = dummyTeamData?.filter(
        (list) => list.parentCompanyId === teamId
      );
      setAllTeamsData(team);
    }
  }, [teamId, dummyTeamData]);

  useEffect(() => {
    try {
      const getAllTeam = async () => {
        const response = await axios.get(getAllExistedTeam());
        setAllTeamsData(response.data.data);
        setDummyTeamData(response.data.data);
      };
      companyType === "GP" && getAllTeam();
    } catch (err) {
      console.log(err);
    }
  }, [companyType]);

  useEffect(() => {
    setTableData(data?.transactions);
  }, [data]);

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setPaginationModel({page: newPage, pageSize: pageSize});
  };

  const handleSearching = (event) => {
    const dataToSearchFrom = data?.transactions;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "transactionId",
            "email",
            "phone",
            "chargepoint_id",
            "connectorId",
            "payment_type",
            "totalAmountPaid",
            "totalAmountCharged",
            "totalRefund",
            "starttime",
            "stoptime",
            "status",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "starttime" || key === "stoptime"
                ? yearFirstDateTimeFormat(value).toString()
                : value
                ? value.toString().toLowerCase()
                : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      setTableData(filteredDataArr);
    } else setTableData(data?.transactions);
  };

  const saveToExcel = async () => {
    try {
      const response = await axios.get(transactionExcelDownload({teamId,
        filterteam: selectedTeam,
        filterGPTeam: selectedGPTeam,
        companyType}), {
        responseType: "blob",
      });

      downloadExcel(response.data, "transaction");
    } catch (error) {
      console.error("Error downloading the Excel file", error);
    }
  };

  const openClosePayoutsDetails = () => setOpenPayoutsDetails((prev) => !prev);
  const speedDialActions = [
    {
      icon: (
        <Icon
          icon="heroicons:document-currency-rupee-solid"
          width="27"
          height="27"
        />
      ),
      name: "Payouts",
      onClick: openClosePayoutsDetails,
    },
  ];
  const onChangeCompany = (event) => {
    setSelectedTeam(event.target.value);
    setSelectedGPTeam([]);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    if (companyType === "GP" && event.target.value.length > 0) {
      const teamFilterValue = dummyTeamData.filter((filter) =>
        event.target.value.includes(filter.parentCompanyId)
      );
      setAllTeamsData(teamFilterValue);
    } else setAllTeamsData(dummyTeamData);
  };

  return (
    <Page title="Transactions | Smart-CMS">
      <Card sx={{ py: 2, px: 1, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            // gridTemplateColumns: {xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)",},
            gridColumnGap: "0.8rem",
            alignItems: "center",
            padding: "15px",
            marginRight:{xs:"-45px", sm:"0"},
            marginLeft:{xs:"-13px", sm:"0"},
          }}
        >
          {/* <CommonSearchbar
            placeholder="Search"
            searchFunc={(e) => handleSearching(e)}
          /> */}
          {(companyType === "GP" || companyType === "P") &&
            user?.company?.id === teamId && (
              <Box sx={{ width: {xs: "120%", sm: "25%"} }}>
                <FormControl fullWidth size="small"
                sx={{
                  fontSize: { xs: "0.7rem", sm: "1rem" }, // Adjust font size for responsiveness
                }}>
                  <InputLabel id="demo-simple-select-label"
                  sx={{
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Responsive label font size
                  }}>
                    {companyType === "GP" ? "Select Company" : "Select Team"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    // label="All Time"
                    value={selectedTeam}
                    onChange={(event) => onChangeCompany(event)}
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust select font size
                      height: { xs: 35, sm: 42 }, // Adjust box height
                    }}
                  >
                    {user?.role
                      ?.filter((team) => team.teamId !== user?.company?.id)
                      ?.map((team) => (
                        <MenuItem
                          key={team.teamId}
                          value={team.teamId}
                          sx={{
                            fontSize: { xs: "0.8rem", sm: "1rem" }, // Responsive menu item font size
                            // pl: 4,
                          }}
                        >
                          {team.teamName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          {companyType === "GP" && (
            <Box sx={{  width: {xs: "110%", sm: "25%"} }}>
              <FormControl fullWidth size="small"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust font size for responsiveness
              }}>
                <InputLabel id="demo-simple-select-label"
                sx={{
                  fontSize: { xs: "0.75rem", sm: "1rem" }, // Responsive label font size
                }}>
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  // label="All Time"
                  value={selectedGPTeam}
                  onChange={(event) => {
                    setSelectedGPTeam(event.target.value);
                    setPaginationModel({
                      page: 0,
                      pageSize: 10,
                    });
                  }}
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust select font size
                    height: { xs: 35, sm: 42 }, // Adjust box height
                  }}
                >
                  {allTeamsData?.map((team) => (
                    <MenuItem
                      key={team.companyId}
                      value={team.companyId}
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "1rem" }, // Responsive menu item font size
                      }}
                    >
                      {team.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {tableData?.length > 0 && (
            <Button
              variant="contained"
              onClick={saveToExcel}
              sx={{ whiteSpace: "nowrap",
                minWidth: { xs: "40px", sm: "80px" }, // Adjust width for small and large screens
                height: {xs:"35px", sm: "42px"},
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                mr: 4, }}
            >
              <Box sx={{ display: { xs: "flex", sm: "none" } }}>
        <Icon icon="material-symbols:download" width="20" height="20" />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "flex" } }}>Export</Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "100%", // Position above the button
          left: "50%",
          transform: "translateX(-50%)",
          whiteSpace: "nowrap",
          background: "black",
          color: "white",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "12px",
          visibility: "hidden",
          opacity: 0,
          transition: "opacity 0.2s",
          pointerEvents: "none",
          zIndex: 1,
        }}
        className="tooltip"
      >
        Export to Excel
      </Box>

            </Button>
          )}
        </Box>

        <BaseTable
          isReadOnly={isReadOnly}
          rows={tableData ?? []}
          columns={finalTableColumns}
          loading={isFetching}
          getRowId={(row) => row.transactionId}
          pageSize={pageSize}
          showExportCsvBtn={false}
          csvMorphemes="transactions"
          csvColumns={initialColumns}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalRows={data?.totalRecord || 0}
          serverRowFetcher={true}
          rowsPerPageOptions={[10, 30, 50, 100]}
          paginationModel={paginationModel}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              overflow: "hidden   !important",
              lineHeight: "20px   !important",
              whiteSpace: "normal  !important",
            },
          }}
        />
        <StyledSpeedDial
          ariaLabel="SpeedDial playground example"
          hidden={!isUserFromGP || !hasTransactionReadAccess }
          icon={
            <SpeedDialIcon
              openIcon={<Icon icon={editFill} width={24} height={24} />}
            />
          }
          direction={"up"}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))}
        </StyledSpeedDial>
      </Card>
      <TransactionPayoutUpdModal
        open={openPayoutsDetails}
        onClose={openClosePayoutsDetails}
      />
    </Page>
  );
};
