import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { ChargerActionMenu } from "./ChargerActionMenu";
import {
  withinTimeBounds,
  yearFirstDateTimeFormat,
} from "src/utils/formatTime";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isSameDay } from "date-fns";
import ThrottledComponent from "src/common/components/ThrottledComponent";
export const chargerPointColumns =(isReadOnly)=>( [
  {
    field: "chargepoint_id",
    headerName: "Chargebox ID",
    flex: 0.8,
    minWidth:110,
    renderCell: ({ value, id }) => (<>
      { isReadOnly?value:<Link underline="none" component={RouterLink} to={`${id}`}>
        {value}
      </Link>}
    </>
    ),
  },
  {
    field: "team_name",
    headerName: "Team",
    flex: 0.6,
    minWidth:130,
  },
  {
    field: "street",
    headerName: "Address",
    flex: 1,
    minWidth:250,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth:120,
  },
  {
    field: "visibility",
    headerName: "Visibility",
    flex: 0.6,
    minWidth:90,
    renderCell: ({ value }) => (
      <span>{Number(value) === 0 ? "Private" : "Public"}</span>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.6,
    minWidth:90,
    renderCell: ({ value }) => {
      let statusColor = value === "Inactive" ? "red" : "green";
      return (
        <span style={{ marginLeft: "10px", color: statusColor }}>{value}</span>
      );
    },
  },
  {
    field: "lastHeartbeatTimestamp",
    headerName: "Last Connected",
    type: "date",
    flex: 1,
    minWidth:180,
    renderCell: ({ value }) => <span>{yearFirstDateTimeFormat(value)}</span>,
  },
  {
    field: "Actions",
    width: 80,
    sortable: false,
    renderCell: (props) => (
      <ThrottledComponent>
        <ChargerActionMenu {...props} />
      </ThrottledComponent>
    ),
  },
]);

export const ChargerFormTabs = [
  {value:"general",label:"GENERAL"},
  {value:"address",label:"ADDRESS"},
  {value:"settings",label:"SETTINGS"},
  {value:"remote operations",label:"REMOTE OPERATIONS"},
  { value: "operating_instruction", label:"OPERATING INSTRUCTIONS" },
  { value: "report_incidents", label: "REPORTED INCIDENTS" },
  // {value:"config",label:"CONFIG"},
  {value:"logs",label:"LOGS"},
]
export const hideForTabs = ["remote operations","logs","config"]
export const getChargerDefaultValues = ({ teamId }) => ({
  chargepoint_id: "",
  chargepoint_name:"",
  excludeFromEmail: false,
  registrationStatus: "",
  charger_type:"DC",
  description: "",
  adminAddress: "",
  locationLongitude: "",
  locationLatitude: "",
  note: "",
  endpointAddress: "",
  ocppProtocol: "",
  chargePointVendor: "",
  chargePointSerialNumber: "",
  chargeBoxSerialNumber: "",
  fwVersion: "",
  fwUpdateStatus: "",
  fwUpdateTimestamp: "",
  iccid: "",
  imsi: "",
  meterType: "",
  meterSerialNumber: "",
  diagnosticsStatus: "",
  diagnosticsTimestamp: "",
  lastHeartbeatTimestamp: "",
  insertConnectorStatusAfterTransactionMsg: false,
  name: "",
  street: "",
  houseNumber: "",
  zipCode: "",
  city: "",
  country: "",
  state: "",
  priceGroupId:null,
  companyId: teamId,
  connectors: [
    { connectorId: "", kw: "", volt: "", connectorType: "", priceGroupId: "" },
  ],
  showOnPortal: false,
  employeeOrResident: true,
  payWithWallet: true,
  payWithCard: true,
  enableNotifications:true,
  notificationRecipients:"",
  OcppStationBooted:true,
  OcppStationStatusFailure:true,
  OcppStationWebSocketConnected:true,
  OcppStationWebSocketDisconnected:true,
  OcppTransactionStarted:true,
  OcppTransactionEnded:true,
  tolerance_watt:300,
  meter_interval_sec:30,
  amenities: [],
  photos: [],
  operator_email: "",
  operator_number1: "",
  operator_number2: "",
  faq: [
    { question: "", answer: "", id: 0 }
  ]
});

export const NOTIFICATION_CONSTANTS = [
  "OcppStationBooted",
  "OcppStationStatusFailure",
  "OcppStationWebSocketConnected",
  "OcppStationWebSocketDisconnected",
  "OcppTransactionStarted",
  "OcppTransactionEnded",
]

export const AMENITIES = [
 { text: "Gym", value: "GYM" },
 { text: "Cinema", value: "THEATER" },
 { text: "Salon", value: "SALON" },
 { text: "Pilgrimage Sites", value: "PILGRIMAGE SITE" },
 { text: "Public Washroom", value: "PUBLIC WASHROOM" },
 { text: "Park", value: "PARK" },
 { text: "Kid Acitivity Zone", value: "KID ACTIVITY ZONE" },
 { text: "Auto Service Center", value: "AUTO SERVICE" },
 { text: "Medical Store", value: "MEDICAL STORE" },
 { text: "Hospital", value: "HOSPITAL" },
 { text: "24/7 Monitoring", value: "24/7 MONITORING" },
 { text: "Hotel", value: "HOTEL" },
 { text: "ATM", value: "ATM" },
 { text: "Restaurant", value: "RESTAURANT" },
 { text: "Shopping Center", value: "SHOPPING" },
 { text: "Parking", value: "PARKING" },
];

export const reportIssuesColumn = [
  {
    field: "serial_number",
    headerName: "Serial",
    flex: 0.8,
  },
  {
    field: "charge_box_id",
    headerName: "Charger ID",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Date & Time",
    type: "date",
    flex: 1,
    renderCell: ({ value }) => <span>{yearFirstDateTimeFormat(value)}</span>,
  },
  {
    field: "category",
    headerName: "Reported Issues Category",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <Typography noWrap>{value}</Typography>
      </Tooltip>
    )
  },
  {
    field: "comment",
    headerName: "Comments",
    flex: 1,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <Typography noWrap>{value}</Typography>
      </Tooltip>
    )
  }
]