import { useAccess } from "src/hooks/useAccess.hook";
import editFill from "@iconify/icons-eva/edit-fill";
import disableFill from "@iconify/icons-eva/slash-fill";
import enableFill from "@iconify/icons-eva/checkmark-circle-2-fill";
import viewFill from "@iconify/icons-eva/eye-fill";
import Flip2Fill from "@iconify/icons-eva/flip-2-fill";
import chargerIcon from "@iconify/icons-eva/flash-outline";
import deleteFill from "@iconify/icons-eva/trash-2-outline";
import { ActionsMenu } from "../Actions/ActionsMenu";
import { useMutate } from "src/hooks/useFetch.hook";
import { getTeamUrl, tagIdUrl } from "src/react-query/endPoints";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useCallback, useMemo } from "react";
import { FEATURE } from "src/util/enums";
import { useDispatch } from "react-redux";
import { dialogSlice } from "src/store/dailog/dailog.slice";

export const TagActionMenu = ({ id, row }) => {
  const dispatch = useDispatch();
  const isReadOnly = useAccess(FEATURE.TAG, "R");
  const hasEditAccess = useAccess(FEATURE.TAG, "U");
  const hasDeleteAccess = useAccess(FEATURE.TAG, "D");
  const { teamId } = useSelectedTeam();
  const { mutate } = useMutate("tags");
  
  const disabled = useMemo(() => row.max_active_trans_count === 0, [row]) 
  const onEnableDisable = useCallback(() => {
    mutate({ url: getTeamUrl(teamId, tagIdUrl(id)), 
      method: "PATCH" });
  }, [teamId, id, mutate]);

  const onRemove = useCallback(() => {
    mutate({ url: getTeamUrl(teamId, tagIdUrl(id)), 
      method: "DELETE" });
  }, [teamId, id, mutate]);

  const attachCharger = useCallback(() => {
    dispatch(dialogSlice.actions.setDialog({ 
      open: true, 
      id: { row }
    }));
  }, [dispatch, id, row]);

  const actionList = useMemo(
    () => [
      {
        id: "view",
        title: "View",
        icon: viewFill,
        routerLink: `/tag/${id}`,
        hidden: !isReadOnly,
      },
      {
        id: "edit",
        title: "Edit",
        icon: editFill,
        routerLink: `/tag/${id}`,
        hidden: !hasEditAccess,
      },
      {
        id: "delete",
        title: "Delete",
        icon: deleteFill,
        action: onRemove,
        hidden: !hasDeleteAccess,
      },
      {
        id: disabled ? "enable":"disable",
        title: disabled ? "Enable":"Disable",
        icon:disabled ? enableFill : disableFill,
        iconcolor: disabled ? "#48C13E": "red",
        action: onEnableDisable,
        hidden: !hasDeleteAccess,
      },
      {
        id: "transactions",
        title: "View Transactions",
        icon: Flip2Fill,
        routerLink: `/transactions?tag=${id}`
      },
      {
        id: "attach",
        title: "Attach Charger",
        icon: chargerIcon,
        action: attachCharger
      }
    ],
    [id, isReadOnly, hasEditAccess, hasDeleteAccess, onEnableDisable,disabled, attachCharger]
  );

  return <ActionsMenu menuList={actionList} />;
};
