import { useState, useEffect } from "react";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

const useHandleSearching = (customers, isFetching) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      setTableData([...customers]);
    }
  }, [isFetching, customers]);

  const handleSearching = (event) => {
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = customers.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "email",
            "phone",
            "active",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "createdAt"
                ? yearFirstDateTimeFormat(value).toString()
                : value === "Y"
                ? "enabled"
                : value === "N"
                ? "disabled"
                : value
                ? value.toString().toLowerCase()
                : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      setTableData(filteredDataArr);
    } else {
      setTableData([...customers]);
    }
  };

  return { tableData, handleSearching };
};

export default useHandleSearching;
