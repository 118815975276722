import { useState, useEffect } from "react";

const ThrottledComponent = ({ children, delay = 500 }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => setRender(true), delay);
    return () => clearTimeout(handler);
  }, [delay]);

  return render ? children : null;
};

export default ThrottledComponent