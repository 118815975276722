const enums = {
  toolbars: {
    USER: "user",
    CHARGER: "charger",
    TEAM: "team",
  },
  path: {
    DASHBOARD_APP: "/dashboard",
    LOGIN: "/login",
    TRANSACTIONS: "/transactions",
  },
  SEVERITY: {
    ERROR: "error",
    SUCCESS: "success",
  },
  PRICE_PER: {
    KWH: "kwh",
    MIN: "min",
  },
  PRICE_TYPE: {
    FIXED: 'fixed',
    VARIABLE: 'variable'
  },
  DAYS: {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0
  },
  DAYS_IN_STRING: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
  MODAL_STYLE: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    height: "500px",
  },
  REFUND_STATUS: {
    PROCESSED: 'processed',
    FAILED: 'failed',
    PENDING: 'pending'
  }
};

export const FEATURE = {
  CHARGER: "ChargerPoint",
  CHARGERPROFILE:"ChargingProfile",
  TEAM: "Team",
  TAG: "Tag",
  PRICEGROUP: "PriceGroup",
  TRANSACTION: "Transaction",
  SERVICEREQUEST:"ServiceRequest",
  USERS: "Users",
  SETTINGS: "Settings",
  CUSTOMERS: "Customers",
  ROLES: "Role",
  COMPANY: "Company",
  REFUND: 'Refund',
  FINANCE: "Finance",
  CMCUSTOMERS: "CMCustomers",
  REPORT: "Report",
  CHARGEZONE: "ChargeZone",
  MEMBERS: "Members",
};

export const PAYMENT_TYPE = {
  QRCODE: 'qr_code',
  LINK: 'link'
}

export const PAYMENT_GATEWAY = {
  1: {
    gateway: 'RAZORPAY',
    endpoint: 'generateQr',
    beforeButtonText: 'UPI QR Code',
    afterButtonText: 'Razorpay QR Code',
    type: 'qr_code'
  },
  2: {
    gateway: 'STRIPE',
    endpoint: 'generate_payment_link',
    afterButtonText: 'Stripe Payment Link',
    beforeButtonText: 'Payment Link',
    type: 'link'
  }
};

export default enums;
