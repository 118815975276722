import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { CompanyActionMenu } from "./CompanyActionMenu";

export const getCompanyDefaultValues = () => ({
  subscriptionId: "",
  emailId: "",
  name: "",
  address: {
    street: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  },
  active: true,
  paymentConfirmationNo: "",
  rootUser: "",
  firstName:"",
  lastName:"",
  comments: "",
  validUntil: "",
  locationLongitude: "",
  locationLatitude: "",
  paymentCurrency:"",
  paymentGatewayId:"",
  transactionPercent:"",
  taxPercent:"",
  whitelabelInfo:{ url:"" ,  whitelabel_provider: "", map_marker_active:"" , map_marker_inUse:"" , map_marker_inactive:"", terms_n_condition_url: "", show_all_chargers: !1 },
});

export const acceptableCurrencyList = [
{value:"INR",title:"Indian Rupees"},
{value:"CAD",title:"Canadian Dollars"},
]

export const companyListColumns = (isReadOnly)=> (
  [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth:150,
      renderCell: ({ value, id }) => (
        <>
       {isReadOnly?value: <Link underline="none" component={RouterLink} to={`${id}`}>
          {value}
        </Link>}
        </>
      ),
    },
    {
      field: "creationTime",
      headerName: "Creation Time",
      flex: 1,
      type: "date",
      minWidth:200,
      renderCell: ({ value }) => (
        <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
          <span>{yearFirstDateTimeFormat(value)}</span>
        </Tooltip>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      minWidth:200,
    },
    {
      field: "Actions",
      width: 70,
      sortable: false,
      renderCell: CompanyActionMenu,
    },
  ]
);
