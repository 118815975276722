// material
import { alpha, styled } from "@mui/material/styles";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Paper,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { BaseChart } from "src/components/charts/BaseOptionChart";
import { useEffect, useMemo } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const RootStyleNew = styled(Card)(
  ({ theme, maxwidth, minheight = "100%", backgroundcolor, color }) => ({
    maxWidth: maxwidth,
    minHeight: minheight,
    boxShadow: "none",
    backgroundColor: backgroundcolor,
    color: color,
    padding: theme.spacing(2),
    display: "grid",
    gap: "5px 0",
  })
);

export const StyledFormControl = styled(FormControl)(
  ({ theme, bgcolor, maxwidth = "100px" }) => {
    return {
      background: `linear-gradient(35deg, ${theme.lighter} 10%, ${theme.light} 60%, ${theme.main} 88%, ${bgcolor} 100%)`,
      borderRadius: "5px",
      maxWidth: maxwidth,
    };
  }
);

const StyledPaper = styled(Paper)({
  height: "460px",
  minWidth: "160px",
  padding: "7px 5px",
});

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

const IconWrapper = styled(Box)(({ svgcolor }) => ({
  margin: "2px",
  border: "none",
}));
// ----------------------------------------------------------------------

export default function DashboardCard({
  title,
  count,
  icon,
  colorTheme,
  unit = "",
}) {
  return (
    <RootStyle
      sx={{ backgroundColor: colorTheme?.lighter, color: colorTheme?.darker }}
    >
      <IconWrapperStyle
        sx={{
          color: colorTheme?.dark,
          backgroundImage: `${
            colorTheme
              ? `linear-gradient(135deg, ${alpha(
                  colorTheme?.dark,
                  0
                )} 0%, ${alpha(colorTheme?.dark, 0.24)} 100%)`
              : undefined
          }`,
        }}
      >
        {icon}
      </IconWrapperStyle>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
      <Typography variant="h3">
        {count === "N/A" ? "N/A" : fShortenNumber(count)}
        {unit ? " " + unit : ""}
      </Typography>
    </RootStyle>
  );
}

export const DashboardNumberStatsCard = ({
  title,
  count,
  icon,
  footer,
  percentChange,
  colorTheme,
  bgColor,
  fontColor,
  dropdownValues,
  isFetching,
}) => {
  return (
    <RootStyleNew
      backgroundcolor={bgColor}
      color={fontColor}
      // maxwidth="300px"
      // minheight="142px"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bolder", fontSize: "1.01rem" }}
        >
          {title}
        </Typography>
      </Stack>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h3">{count}</Typography>
          <Stack flexDirection="row" alignItems="center">
            {icon && (
              <IconWrapper>
                <img src={icon} alt={`${title}-icon`} />
              </IconWrapper>
            )}

            <Typography variant="subtitle2" sx={{fontSize:"12px"}}marginLeft={0.5}>
              {percentChange ?? footer}
            </Typography>
          </Stack>
        </>
      )}
    </RootStyleNew>
  );
};

export const DashboardDonutStatsCard = ({
  stats,
  success,
  error,
  isFetchingChargerList,
}) => {
  const options = useMemo(
    () => ({
      chart: {
        type: "donut",
      },
      legend: {
        //  show:false,
        position: "bottom",
        offsetX: -20,
        offsetY: -55,
        floating: true,
        fontSize: "9px",
        fontWeight: "bolder",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
          },
          expandOnClick: true,
        },
      },
      labels: ["online devices", "offline devices"],
      colors: [success, error],
    }),
    [error, success]
  );
  const dataSeries = useMemo(() => {
    return stats.length > 0
      ? stats.reduce(
          (dataSeries, { title, count }) =>
            title !== "Total Devices" ? [...dataSeries, count] : dataSeries,
          []
        )
      : [];
  }, [stats]);
  return (
    <StyledPaper elevation={4}>
      {stats &&
        stats.length > 0 &&
        stats.map(
          ({ title, count, icon, svgColor, fontColor, isFetching }, index) => {
            return (
              <Stack key={`${title}-${index}-${count}`}>
                <Box paddingX={1} marginY={1}>
                  <Stack spacing={1}>
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bolder" }}
                      >
                        {title}
                      </Typography>
                      <IconWrapper>
                        <img src={icon} alt={`${title}-icon`} />
                      </IconWrapper>
                    </Stack>
                    <Typography variant="h3" sx={{ color: fontColor }}>
                      {isFetching ? <CircularProgress /> : count}
                    </Typography>
                  </Stack>
                </Box>
                {index < stats.length - 1 && <Divider variant="middle" />}
              </Stack>
            );
          }
        )}
      {isFetchingChargerList ? (
        <CircularProgress />
      ) : (
        <BaseChart
          series={dataSeries}
          options={options}
          type="donut"
          height={185}
          width={152}
        />
      )}
    </StyledPaper>
  );
};

export const DashboardBarGraphStatsCard = ({
  title,
  colorTheme,
  bgColor,
  fontColor,
  xaxis,
  xaxisType,
  yaxis,
}) => {
  const options = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "110%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 3,
        colors: ["transparent"],
      },
      xaxis: {
        categories: xaxis,
        type: xaxisType,
        title: {
          text: "Time",
          style: {
            fontWeight: "bold",
          },
        }
      },
      yaxis: {
        title: {
          text: "Kw",
          style: {
            fontWeight: "bold",
          },
        },
        labels: {
          formatter: (value) => { return (value / 1000)}
        }
      },
      fill: {
        opacity: 1,
      },
      colors: [colorTheme.dark],
    }),
    [colorTheme.dark, xaxis, xaxisType]
  );

  const series = useMemo(
    () => [
      {
        name: "Usage",
        data: yaxis,
      },
    ],
    [yaxis]
  );
  return (
    <RootStyleNew
      backgroundcolor={bgColor}
      color={fontColor}
      // maxwidth="473px"
      // minheight="120px"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bolder", fontSize: "1.13rem" }}
        >
          {title}
        </Typography>
      </Stack>
      <BaseChart
        options={options}
        series={series}
        height={480}
        // width={400}
        type="bar"
      />
    </RootStyleNew>
  );
};

export const DashboardMapCard = ({ title, bgColor, fontColor, children }) => {
  return (
    <RootStyleNew backgroundcolor={bgColor} color={fontColor}>
      <Stack>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bolder",
            fontSize: "1.13rem",
            marginBottom: "5px",
          }}
        >
          {title}
        </Typography>
        {children}
      </Stack>
    </RootStyleNew>
  );
};
