import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { fDateTime } from "src/utils/formatTime";
import { UserActionMenu } from "./UserActionMenu";

const dateFormat = "yyyy-MM-dd hh:mm a";

export const userListColumns = (isReadOnly)=>([
  {
    field: "username",
    headerName: "Username",
    flex: 1,
    minWidth:150,
    renderCell: ({ value, id }) => (<>
      {isReadOnly ?value:<Link underline="none" component={RouterLink} to={`${id}`}>
        {value}
      </Link>}
    </>
    ),
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    minWidth:100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    minWidth:100,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.2,
    minWidth:220,
    renderCell:({value})=>(
      <Tooltip title={value} placement="top"><span>{value}</span></Tooltip>
    )
  },
  {
    field: "createDate",
    headerName: "Created on",
    type: "date",
    flex: 1,
    minWidth:180,
    valueFormatter: ({ value }) => {
      return fDateTime(value, dateFormat);
    },
  },
  {
    field: "Actions",
    width: 70,
    sortable: false,
    renderCell: UserActionMenu,
  },
]);

export const getUserDefaultValues = () => ({
  userId: "",
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
});
