import { useState, useRef, lazy, Suspense } from "react";
import { TabContext, TabPanel, LoadingButton } from "@mui/lab";
import { RootTabStyle, RootTabItemStyles } from "src/theme/overrides/Tabs";
import { Stack, Card, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { membersTabList } from "./members.constant";

const AccountManagementLazy = lazy(() => import("./AccountManagement"));

const MembersManagement = () => {
  const accountManagementRef = useRef();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(membersTabList[0].value);

  const onUpdate = () => {
    accountManagementRef.current.onUpdate();
  };

  return (
    <TabContext value={selectedTab}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItem="center"
        sx={{ width: membersTabList.length > 1 ? "100%" : "50%" }}
      >
        <RootTabStyle
          indicatorColor="transparent"
          centered
          length={membersTabList.length}    
          onChange={(tab) => setSelectedTab(tab)}
        >
          {membersTabList.map((tab) => (
            <RootTabItemStyles value={tab.value} label={tab.label} />
          ))}
        </RootTabStyle>
      </Stack>
      <Card sx={{ p: 4 }}>
        <TabPanel value="accountManagement">
          <Suspense fallback={<div>Loading...</div>}>
            <AccountManagementLazy
              ref={accountManagementRef}
              setLoading={setLoading}
            />
          </Suspense>
        </TabPanel>
        <Grid container mt={3}>
          <Grid item xs={6} lg={6} paddingLeft={0}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                onClick={onUpdate}
              >
                Update
              </LoadingButton>
              <LoadingButton
                size="large"
                type="button"
                variant="outlined"
                component={RouterLink}
                to="/management"
              >
                Cancel
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </TabContext>
  );
};

export default MembersManagement;
