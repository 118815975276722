export const CMS_ENDPOINT = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/cms`;
export const CMS_ENDPOINT_V1 = `${CMS_ENDPOINT}/v1`;
export const S3_BUCKET_URL = `${process.env.REACT_APP_S3_BUCKET_URL}`;
export const WEB_APP_ENDPOINT = `${process.env.REACT_APP_WEB_APP_ENDPOINT}`;
export const CMS_NEST_ENDPOINT = `${process.env.REACT_APP_CSMS_NEST_ENDPOINT}/api/v3/cms`;

export const loginUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/login`;
export const resetUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/resetpwdrequest`;
export const verificationAndResetUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/resetpwd`;
export const changePasswordUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/changepwd`;
export const getTnCUrl = (username) =>
  `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/check_TnC/${username}`;
export const updateTnCUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/update_TnC`;
const setupUrl = `${CMS_ENDPOINT_V1}/setup`;
export const userUrl = `${CMS_ENDPOINT_V1}/profile`;
export const usersUrl = `${CMS_ENDPOINT_V1}/users`;
export const rolesUrl = `${CMS_ENDPOINT_V1}/roles`;
export const roleUrl = `${CMS_ENDPOINT_V1}/role`;
export const getRoleUrlId = (id) => `${roleUrl}/${id}`;
export const categoryUrl = `${setupUrl}/category`;
export const userTypeUrl = `${setupUrl}/usertype`;
export const chargersUrl = `/chargers`;
export const bulkCalPayoutsUrl = `/payouts/bulk_calculated`;
export const updateBulkPayoutsUrl = `/payouts/bulk`;
export const chargerUrl = `/charger`;
export const chargerIdUrl = (chargerId) => `${chargerUrl}/${chargerId}`;
export const tagsUrl = `/tags`;
export const tagsForRemoteOpUrl = `/tags/remoteop`;
export const availableTagsUrl = `/availabletags`;
export const tagUrl = `/tag`;
export const tagIdUrl = (tagId) => `${tagUrl}/${tagId}`;
export const tagChargerUrl = (tagId, chargerId = null) =>
  `${tagIdUrl(tagId)}/chargers${chargerId ? "/" + chargerId : ""}`;
export const tagUserUrl = (teamId, tagId) =>
  `${getTeamUrl(teamId, tagIdUrl(tagId))}/users`;
export const priceGroupsUrl = `/pricegroups`;
export const priceGroupUrl = `/pricegroup`;
export const priceGroupIdUrl = (priceGroupId) =>
  `${priceGroupUrl}/${priceGroupId}`;
export const transaction = `${CMS_ENDPOINT_V1}/company`;
export const transactionsUrl = `/transactions`;
export const transactionUrl = "/transaction";
export const refundUrl = `${CMS_ENDPOINT_V1}/refund`;
export const chargerTransactions = (id) => `/charger/${id}/transactions`;
export const chargerActiveTransactions = (id) =>
  `/charger/${id}/transactions/active`;
export const tagTransactions = (id) => `/tag/${id}/transactions`;
export const transactionIdUrl = (transactionId) =>
  `${transactionUrl}/${transactionId}`;
export const meterValueUrl = (url, connectorId) =>
  `${url}/meter_value/${connectorId}`;
export const teamsUrl = (parentTeamId) =>
  `${CMS_ENDPOINT_V1}/teams/${parentTeamId}`;
export const teamUrl = `${CMS_ENDPOINT_V1}/team`;
export const teamUrlId = (id) => `${teamUrl}/${id}`;
export const teamUsersUrl = `/users`;
export const getTeamUrl = (teamId, url, id) => {
  if (id) return `${CMS_ENDPOINT_V1}/team/${teamId}${url}/${id}`;
  return `${CMS_ENDPOINT_V1}/team/${teamId}${url}`;
};
export const getTeamV3Url = (teamId, url, id) => {
  if (id) return `${CMS_NEST_ENDPOINT}/team/${teamId}${url}/${id}`;
  return `${CMS_NEST_ENDPOINT}/team/${teamId}${url}`;
};
export const newGetTeamUrl = (teamId, id) => {
  if (id) return `${CMS_ENDPOINT_V1}/team/${teamId}/charging-profile/${id}`;
  return `${CMS_ENDPOINT_V1}/team/${teamId}/charging-profile`;
};
export const getSettlementPercentageUrl = `${CMS_ENDPOINT_V1}/team/settlementpercentage`;
export const getPaymentDetailsForPriceGroupUrl = (teamId) =>
  `${CMS_ENDPOINT_V1}/team/${teamId}/paymentDetails`;
export const getPaymentDetailsOfCompany = (companyId) =>
  `${CMS_ENDPOINT_V1}/company/${companyId}/paymentDetails`;
export const getUserDetailUrl = (id) => `${CMS_ENDPOINT_V1}/user/${id}`;
export const addUserUrl = `${CMS_ENDPOINT_V1}/user`;
export const getUserIdUrl = (userId) => `/user/${userId}`;
export const getUserProfile = () => `${CMS_ENDPOINT_V1}/profile`;
// export const companyUrl = `/cms/v1/company`; // OLD V1 VERSION
export const companyUrl = `/cms/v2/company`;
export const getCompanyUrlId = (id) => `${companyUrl}/${id}`;
export const featureUrl = `${CMS_ENDPOINT_V1}/feature`;
export const remoteOperationUrl = `${CMS_ENDPOINT_V1}/remote/operation`;
export const remoteOperationTaskUrl = (chargerId, taskId) =>
  `${CMS_ENDPOINT_V1}/remote/operation/charger/${chargerId}/task/${taskId}`;
export const viewLogsUrl = (id) => `${chargerIdUrl(id)}/logs`;
export const getConnectorsUrl = (id) => `${chargerIdUrl(id)}/connectors`;
export const getTagsByChargerIdUrl = (chargerId) =>
  `${chargerIdUrl(chargerId)}/tags`;
export const customerUrl = `${CMS_ENDPOINT_V1}/customer`;
export const cmCustomerUrl = `${CMS_ENDPOINT_V1}/root/customer`;
export const getCustomerById = (id) => `${customerUrl}/${id}`;
export const customerTagsById = (id, userTagId) =>
  !userTagId
    ? `${customerUrl}/${id}/tags`
    : `${customerUrl}/${id}/tags/${userTagId}`;
export const uploadImgUrl = `${S3_BUCKET_URL}/util/logo-upload`;
export const serviceRequestUrl = `${CMS_ENDPOINT_V1}/support`;
export const serviceRequestForParentUrl = (teamId) =>
  `${CMS_ENDPOINT_V1}/support/team/${teamId}`;
export const getServiceRequestDetailsUrl = (id) => `${serviceRequestUrl}/${id}`;
export const chargingProfileListUrl = () =>
  `${CMS_ENDPOINT_V1}/charging-profile`;
export const chargingProfileDetailsUrl = (chargingProfileId) =>
  `${chargingProfileListUrl()}/${chargingProfileId}`;
export const paymentGatewayUrl = `/cms/v2/paymentgateway`;
export const availablePaymentGatewayListUrl = `${paymentGatewayUrl}/currency`;
// export const paymentGateway =(paymentGatewayId)=> `${paymentGatewayUrl}/${paymentGatewayId}`
export const logFilterData = (
  teamId,
  chargerId,
  startDate,
  endDate,
  timeStart,
  timeEnd
) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/charger/${chargerId}/log/time_interval?startDate=${startDate}&endDate=${endDate}&startTime=${timeStart}&endTime=${timeEnd}`;
};
export const reportTabData = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/report`;
};
export const reportTabHeaderData = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/report-header`;
};
export const reportExcelDownload = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/report/excel_download`;
};
export const getAvailableCharger = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone/availableChargers`;
};
export const getChargeZoneList = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone`;
};
export const deleteChargeZoneById = (teamId, zoneId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone/${zoneId}`;
};
export const updateChargeZoneById = (teamId, zoneId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone/${zoneId}`;
};
export const getChargeZoneById = (teamId, zoneId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone/${zoneId}`;
};
export const createChargeZone = (teamId) => {
  return `${CMS_ENDPOINT_V1}/team/${teamId}/chargezone`;
};
export const updateLicensePayment = (teamId) => {
  return `${CMS_ENDPOINT_V1}/profile/chargerPayment/${teamId}`;
};
export const getLicensePaymentHistory = (teamId) => {
  return `${CMS_ENDPOINT_V1}/profile/paymentHistory/${teamId}`;
};
export const getLicensePaymentStatus = () => {
  return `${CMS_ENDPOINT_V1}/profile/status`;
};
export const getchargerPricing = (teamId) => {
  return `${CMS_ENDPOINT_V1}/profile/chargerPricing/${teamId}`;
};
export const addCommentOnTicket = (ticketId) => {
  return `${CMS_ENDPOINT_V1}/support/${ticketId}/comment`;
};
export const getAuditLog = (ticket_id) => {
  return `${CMS_ENDPOINT_V1}/support/${ticket_id}/audit_log`;
};
export const transactionExcelDownload = ({
  teamId,
  filterteam,
  filterGPTeam,
  companyType,
}) => {
  if (filterGPTeam?.length > 0 || filterteam?.length > 0) {
    if (companyType === "GP") {
      return `${CMS_ENDPOINT_V1}/team/${teamId}/transactions/excel_download?filterCompany=${filterteam.join(
        ","
      )}&filterteam=${filterGPTeam.join(",")}`;
    } else
      return `${CMS_ENDPOINT_V1}/team/${teamId}/transactions/excel_download?filterteam=${filterteam.join(
        ","
      )}`;
  }
  return `${CMS_ENDPOINT_V1}/team/${teamId}/transactions/excel_download`;
};
export const getTransactionUrl = (
  teamId,
  url,
  page,
  pageSize,
  filterteam,
  filterGPTeam,
  companyType
) => {
  if (filterGPTeam?.length > 0 || filterteam?.length > 0) {
    if (companyType === "GP") {
      return `${CMS_ENDPOINT_V1}/team/${teamId}${url}?page=${page}&pageSize=${pageSize}&filterCompany=${filterteam.join(
        ","
      )}&filterteam=${filterGPTeam.join(",")}`;
    } else
      return `${CMS_ENDPOINT_V1}/team/${teamId}${url}?page=${page}&pageSize=${pageSize}&filterteam=${filterteam.join(
        ","
      )}`;
  }
  return `${CMS_ENDPOINT_V1}/team/${teamId}${url}?page=${page}&pageSize=${pageSize}`;
};
export const getAllExistedTeam = () => {
  return `${CMS_ENDPOINT_V1}/teams`;
};

export const getCurrenciesUrl = () => `${CMS_NEST_ENDPOINT}/master/currencies`;
export const getPaymentGateway = (currencyId) =>
  `${CMS_NEST_ENDPOINT}/master/payment_gateway/${currencyId}`;
export const cmsUsersUrl = `${CMS_ENDPOINT_V1}/root/users`;
export const resetPasswordByAdminUrl = `${process.env.REACT_APP_CSMS_API_ENDPOINT}/auth/change/user-password`;