import { Link, Tooltip } from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import ChargingProfileActionMenu from "./ChargingProfileLists/ChargingProfileActionMenu";
import ThrottledComponent from "src/common/components/ThrottledComponent";

export const chargingprofileListColumn = (isReadOnly)=>([
  {
    field: "charging_profile_pk",
    headerName: "Profile ID",
    // flex: 1,
    Width: 150,
    renderCell:({value,id})=>(
    <>
    {isReadOnly?value:<Link  underline="none" component={RouterLink} to={`${id}`}>{value}</Link>}
    </>
    )
},
  {
    field: "stack_level",
    headerName: "Stack Level",
    flex:0.8,
    minWidth: 100,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 120
  },
  {
    field: "charging_profile_purpose",
    headerName: "Profile Purpose",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "charging_profile_kind",
    headerName: "Profile Kind",
    flex: 1,
    minWidth: 150
  },
  {
    field: "recurrency_kind",
    headerName: "Recurrency Kind",
    flex: 1,
    minWidth: 150
  },
  {
    field: "valid_from",
    headerName: "Valid From",
    type: "date",
    flex: 1,
    minWidth: 200,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field: "valid_to",
    headerName: "Valid To",
    type: "date",
    flex: 1,
    minWidth: 200,
    renderCell: ({ value }) => (
      <Tooltip title={yearFirstDateTimeFormat(value)} placement="top">
        <span>{yearFirstDateTimeFormat(value)}</span>
      </Tooltip>
    ),
  },
  {
    field:"actions",
    headerName:"Actions",
    flex:0.6,
    minWidth: 100,
    renderCell: (props) => (
      <ThrottledComponent>
        <ChargingProfileActionMenu {...props} />
      </ThrottledComponent>
    )
  }
]);


export const chargingprofileListRows = [
  {
    profile_id: 1,
    stack_level: 10,
    description: `Aenean lectus. Pellentesque eget nunc. Donec quis orci eget orci vehicula condimentum.\n\nCurabitur in libero ut massa volutpat convallis. Morbi odio odio, elementum eu, interdum eu, tincidunt in, leo. Maecenas pulvinar lobortis est.`,
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "DAILY",
    valid_from: "4/1/2022",
    valid_to: "5/26/2022",
    duration_in_seconds: 149,
    start_schedule: "1/23/2023",
    charging_rate_unit: 210,
    min_charging_rate: 38,
    note: "morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed",
  },
  {
    profile_id: 2,
    stack_level: 8,
    description:
      "Praesent blandit. Nam nulla. Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "WEEKLY",
    valid_from: "11/6/2022",
    valid_to: "10/1/2022",
    duration_in_seconds: 28,
    start_schedule: "9/28/2022",
    charging_rate_unit: 81,
    min_charging_rate: 107,
    note: "ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien",
  },
  {
    profile_id: 3,
    stack_level: 8,
    description:
      "Cras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    charging_profile_purpose: "TX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "WEEKLY",
    valid_from: "7/30/2022",
    valid_to: "8/28/2022",
    duration_in_seconds: 30,
    start_schedule: "11/10/2022",
    charging_rate_unit: 22,
    min_charging_rate: 207,
    note: "est phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu mi nulla ac",
  },
  {
    profile_id: 4,
    stack_level: 7,
    description: `Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum.\n\nIn hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.`,
    charging_profile_purpose: "TX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "DAILY",
    valid_from: "3/10/2023",
    valid_to: "8/20/2022",
    duration_in_seconds: 29,
    start_schedule: "12/11/2022",
    charging_rate_unit: 189,
    min_charging_rate: 198,
    note: "elementum pellentesque quisque porta volutpat erat quisque erat eros viverra",
  },
  {
    profile_id: 5,
    stack_level: 2,
    description: "Fusce consequat. Nulla nisl. Nunc nisl.",
    charging_profile_purpose: "TX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "DAILY",
    valid_from: "6/21/2022",
    valid_to: "4/27/2022",
    duration_in_seconds: 16,
    start_schedule: "3/16/2022",
    charging_rate_unit: 127,
    min_charging_rate: 12,
    note: "nulla nisl nunc nisl duis bibendum felis sed interdum venenatis turpis",
  },
  {
    profile_id: 6,
    stack_level: 3,
    description:
      "Maecenas ut massa quis augue luctus tincidunt. Nulla mollis molestie lorem. Quisque ut erat.",
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "DAILY",
    valid_from: "8/8/2022",
    valid_to: "6/4/2022",
    duration_in_seconds: 213,
    start_schedule: "10/16/2022",
    charging_rate_unit: 44,
    min_charging_rate: 74,
    note: "non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet",
  },
  {
    profile_id: 7,
    stack_level: 5,
    description: `Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis. Ut at dolor quis odio consequat varius.\n\nInteger ac leo. Pellentesque ultrices mattis odio. Donec vitae nisi.`,
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "DAILY",
    valid_from: "3/22/2022",
    valid_to: "6/20/2022",
    duration_in_seconds: 186,
    start_schedule: "7/4/2022",
    charging_rate_unit: 214,
    min_charging_rate: 68,
    note: "augue quam sollicitudin vitae consectetuer eget rutrum at lorem integer tincidunt ante vel ipsum",
  },
  {
    profile_id: 8,
    stack_level: 2,
    description: `Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris.\n\nMorbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.`,
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "RECURRING",
    recurrency_kind: "DAILY",
    valid_from: "6/17/2022",
    valid_to: "4/10/2022",
    duration_in_seconds: 41,
    start_schedule: "9/19/2022",
    charging_rate_unit: 58,
    min_charging_rate: 90,
    note: "nisl nunc nisl duis bibendum felis sed interdum venenatis turpis enim blandit mi in porttitor pede justo eu",
  },
  {
    profile_id: 9,
    stack_level: 5,
    description: `Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis.\n\nSed ante. Vivamus tortor. Duis mattis egestas metus.`,
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RECURRING",
    recurrency_kind: "DAILY",
    valid_from: "5/4/2022",
    valid_to: "9/29/2022",
    duration_in_seconds: 93,
    start_schedule: "4/15/2022",
    charging_rate_unit: 95,
    min_charging_rate: 28,
    note: "in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla",
  },
  {
    profile_id: 10,
    stack_level: 8,
    description: `Sed ante. Vivamus tortor. Duis mattis egestas metus.\n\nAenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh.`,
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RECURRING",
    recurrency_kind: null,
    valid_from: null,
    valid_to: null,
    duration_in_seconds: null,
    start_schedule: null,
    charging_rate_unit: null,
    min_charging_rate: null,
    note: null,
  },
  {
    profile_id: 11,
    stack_level: 6,
    description:
      "Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RECURRING",
    recurrency_kind: "WEEKLY",
    valid_from: "7/5/2022",
    valid_to: "3/4/2023",
    duration_in_seconds: 78,
    start_schedule: "11/12/2022",
    charging_rate_unit: 64,
    min_charging_rate: 130,
    note: "nam nulla integer pede justo lacinia eget tincidunt eget tempus vel pede morbi porttitor lorem",
  },
  {
    profile_id: 12,
    stack_level: 1,
    description:
      "Proin interdum mauris non ligula pellentesque ultrices. Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "DAILY",
    valid_from: "1/12/2023",
    valid_to: "10/7/2022",
    duration_in_seconds: 94,
    start_schedule: "12/12/2022",
    charging_rate_unit: 157,
    min_charging_rate: 147,
    note: "ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel sem sed sagittis nam congue risus semper porta",
  },
  {
    profile_id: 13,
    stack_level: 2,
    description: "In congue. Etiam justo. Etiam pretium iaculis justo.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RECURRING",
    recurrency_kind: "DAILY",
    valid_from: "9/28/2022",
    valid_to: "5/29/2022",
    duration_in_seconds: 128,
    start_schedule: "8/14/2022",
    charging_rate_unit: 202,
    min_charging_rate: 30,
    note: "adipiscing elit proin risus praesent lectus vestibulum quam sapien varius ut blandit non",
  },
  {
    profile_id: 14,
    stack_level: 4,
    description:
      "In hac habitasse platea dictumst. Etiam faucibus cursus urna. Ut tellus.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "DAILY",
    valid_from: "8/19/2022",
    valid_to: "12/19/2022",
    duration_in_seconds: 131,
    start_schedule: "8/10/2022",
    charging_rate_unit: 168,
    min_charging_rate: 218,
    note: "enim blandit mi in porttitor pede justo eu massa donec dapibus",
  },
  {
    profile_id: 15,
    stack_level: 4,
    description: `Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\nEtiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.`,
    charging_profile_purpose: "TX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "WEEKLY",
    valid_from: "3/16/2022",
    valid_to: "7/29/2022",
    duration_in_seconds: 73,
    start_schedule: "4/17/2022",
    charging_rate_unit: 138,
    min_charging_rate: 66,
    note: "sapien sapien non mi integer ac neque duis bibendum morbi non quam nec",
  },
  {
    profile_id: 16,
    stack_level: 8,
    description: `Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum sed magna at nunc commodo placerat.\n\nPraesent blandit. Nam nulla. Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede.`,
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "WEEKLY",
    valid_from: "4/8/2022",
    valid_to: "5/31/2022",
    duration_in_seconds: 172,
    start_schedule: "6/15/2022",
    charging_rate_unit: 212,
    min_charging_rate: 43,
    note: "fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus",
  },
  {
    profile_id: 17,
    stack_level: 4,
    description: `Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.\n\nDuis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus.`,
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: null,
    valid_from: null,
    valid_to: null,
    duration_in_seconds: null,
    start_schedule: null,
    charging_rate_unit: null,
    min_charging_rate: null,
    note: null,
  },
  {
    profile_id: 18,
    stack_level: 9,
    description:
      "Morbi porttitor lorem id ligula. Suspendisse ornare consequat lectus. In est risus, auctor sed, tristique in, tempus sit amet, sem.",
    charging_profile_purpose: "TX_DEFAULT_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "DAILY",
    valid_from: "10/23/2022",
    valid_to: "9/30/2022",
    duration_in_seconds: 216,
    start_schedule: "2/8/2023",
    charging_rate_unit: 68,
    min_charging_rate: 88,
    note: "aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo",
  },
  {
    profile_id: 19,
    stack_level: 3,
    description: `orem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.\n\nVestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio. Curabitur convallis.`,
    charging_profile_purpose: "CHARGE_POINT_MAX_PROFILE",
    charging_profile_kind: "ABSOLUTE",
    recurrency_kind: "DAILY",
    valid_from: "6/30/2022",
    valid_to: "10/29/2022",
    duration_in_seconds: 70,
    start_schedule: "8/18/2022",
    charging_rate_unit: 161,
    min_charging_rate: 126,
    note: "morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit",
  },
  {
    profile_id: 20,
    stack_level: 1,
    description: `Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.\n\nCras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.`,
    charging_profile_purpose: "TX_PROFILE",
    charging_profile_kind: "RELATIVE",
    recurrency_kind: "DAILY",
    valid_from: "9/14/2022",
    valid_to: "8/9/2022",
    duration_in_seconds: 132,
    start_schedule: "10/31/2022",
    charging_rate_unit: 52,
    min_charging_rate: 60,
    note: "tellus in sagittis dui vel nisl duis ac nibh fusce lacus purus aliquet at feugiat non",
  },
];



 export const chargingProfileDropdownOptions ={
  purpose:[
    {label:"CHARGE_POINT_MAX_PROFILE",value:"ChargePointMaxProfile"},
    {label:"TX_PROFILE",value:"TxProfile"},
    {label:"TX_DEFAULT_PROFILE",value:"TxDefaultProfile"},
  ],
  profile_kind:[
    {label:"ABSOLUTE",value:"Absolute"},
    {label:"RELATIVE",value:"Relative"},
    {label:"RECURRING",value:"Recurring"},
  ],
  recurrency_kind:[
    {label:"DAILY",value:"Daily"},
    {label:"WEEKLY",value:"Weekly"},
  ],
  charging_rate_unit:[
    {label:"W",value:"W"},
    {label:"A",value:"A"},
  ]


}  