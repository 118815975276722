import { useCallback } from "react";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { Card } from "@mui/material";
import { BaseTable } from "../BaseTable/BaseTable";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useList } from "src/hooks/useFetch.hook";
import { memberColumns } from "./members.constant";
import useHandleSearching from "src/hooks/useHandleSearching";
import { tabsCurrentlyOpenedForSlice } from "src/store/tabsOpened/tabsOpened.slice";

const MembersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: users, isFetching } = useList("cmsUsers");
  const { handleSearching, tableData } = useHandleSearching(users, isFetching);

  const rowOrCellClickRedirect = useCallback(({ id }) => {
    dispatch(
      tabsCurrentlyOpenedForSlice.actions.setTabsOpened({
        detailsId: id,
        tabOpenedFor: "users",
        tabOpened: "general",
      })
    );
    navigate(`/management/account-management/${id}`);
  }, []);

  return (
    <Card sx={{ p: 2 }}>
      <CommonSearchbar
        placeholder="Search"
        searchFunc={(e) => handleSearching(e)}
      />
      <BaseTable
        rows={tableData ?? []}
        columns={memberColumns}
        loading={isFetching}
        getRowId={(row) => row.userId}
        pageSize={10}
        showExportCsvBtn={false}
        csvMorphemes="ev_drivers"
        rowOrCellClickRedirect={rowOrCellClickRedirect}
      />
    </Card>
  );
};

export default MembersList;
