import { Navigate, Outlet, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
//
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import DashboardAppNew from "./pages/DashboardAppNew";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import Users from "./pages/User";
import NotFound from "./pages/Page404";
import ChargerList from "./components/charger/ChargerList";
import { Charger } from "./components/charger/Charger";
import { TeamList } from "./components/Team/TeamList";
import UserList from "./components/user/UserList";
import User from "./components/user/User";
import { MainLayout } from "./layouts/MainLayout";
import { TransactionsList } from "./components/Transactions/TransactionsList/TransactionsList";
import { TransactionView } from "./components/Transactions/TransactionView";
import { TagList } from "./components/AuthCode/TagList";
import { Tag } from "./components/AuthCode/Tag";
import { PriceGroupList } from "./components/PriceGroup/PriceGroupList";
import { PriceGroup } from "./components/PriceGroup/PriceGroup";
import { Team } from "./components/Team/Team";
import { CustomerList, Customer } from "./components/customer";
// import Course from "./components/course/course/Course";
// import CourseContent from "./components/course/course/CourseContent";
// import AddContent from "./components/course/course/AddContent";

// Reset Password
import { ResetPassword } from "./components/authentication/ResetPassword";
import ChangePassword from "./components/ChangePassword";
// My Profile
import Profile from "./pages/Profile";
import { Company } from "./components/company/Company";
import { RoleList } from "./components/roles/RoleList";
import { Role } from "./components/roles/Role";
import { CompanyList } from "./components/company/CompanyList";
import ServiceRequestView from "./components/ServiceRequest/ServiceRequestView";
import ServiceRequestFormView from "./components/ServiceRequest/SeviceRequestForm/ServiceRequestFormView";
import ChargingProfileView from "./components/charger/ChargingProfile/ChargingProfileView";
import ChargingProfileFormView from "./components/charger/ChargingProfile/ChargingProfileForms/ChargingProfileFormView";

//refund
import Refund from "./components/Refund";
import CMCustomerList from "./components/cmCustomer/CMCustomerList";
import EmailVerification from "./pages/EmailVerification";
import Register from "./pages/Register";
import ReportTabList from "./components/reportTab/ReportTabList";
import ChargeZone from "./components/chargeZone/ChargeZone";
import ServiceRequestComment from "./components/ServiceRequest/ServiceRequestComment";
import Members from "./pages/Members";
import MembersManagement from "./components/Members/MembersManagement";

export default function Router({ isLoggedIn }) {
  return useRoutes([
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: "", element: <Navigate to="/dashboard" /> },
        {
          path: "dashboard",
          element: <Outlet />,
          children: [
            // { path: "", element: <DashboardApp /> },
            { path: "", element: <DashboardAppNew /> },
            { path: "user", element: <Users /> },
            { path: "products", element: <Products /> },
            { path: "blog", element: <Blog /> },
          ],
        },
        {
          path: "charger",
          element: <Outlet />,
          children: [
            { path: "", element: <ChargerList /> },
            { path: "new", element: <Charger /> },
            { path: ":id", element: <Charger /> },
          ],
        },
        {
          path:"chargingprofile",
          element:<Outlet/>,
          children:[
            {path:"",element:<ChargingProfileView/>},
            {path:"new",element:<ChargingProfileFormView/>},
            {path:":chargingProfileId",element:<ChargingProfileFormView/>},
          ],
        },
        {
          path: "team",
          element: <Outlet />,
          children: [
            { path: "", element: <TeamList /> },
            { path: "new", element: <Team /> },
            { path: ":id", element: <Team /> },
          ],
        },
        {
          path: "users",
          element: <Outlet />,
          children: [
            { path: "", element: <UserList /> },
            { path: "new", element: <User /> },
            { path: ":id", element: <User /> },
          ],
        },
        {
          path: "transactions",
          element: <Outlet />,
          children: [
            { path: "", element: <TransactionsList /> },
            { path: ":id", element: <TransactionView /> },
          ],
        },
        {
          path: "tag",
          element: <Outlet />,
          children: [
            { path: "", element: <TagList /> },
            { path: "new", element: <Tag /> },
            { path: ":tagId", element: <Tag /> },
          ],
        },
        {
          path: "pricegroup",
          element: <Outlet />,
          children: [
            { path: "", element: <PriceGroupList /> },
            { path: "new", element: <PriceGroup /> },
            { path: ":priceGroupId", element: <PriceGroup /> },
          ],
        },
        {
          path: "profile",
          element: <Outlet />,
          children: [{ path: "", element: <Profile /> }],
        },
        {
          path: "customer",
          element: <Outlet />,
          children: [
            { path: "", element: <CustomerList /> },
            { path: "new", element: <Customer /> },
            { path: ":id", element: <Customer /> },
          ],
        },
        {
          path: "changepassword",
          element: <ChangePassword />,
        },
        {
          path: "company",
          element: <Outlet />,
          children: [
            { path: "", element: <CompanyList /> },
            { path: "new", element: <Company /> },
            { path: ":companyId", element: <Company /> },
          ],
        },
        {
          path:"servicerequest",
          element: <Outlet />,
          children:[
            { path:"", element:<ServiceRequestView />},
            {path:"new" ,element:<ServiceRequestFormView/>},
            {path:":ticketId",element:<ServiceRequestComment/>}
          ]
        },
        {
          path: "roles",
          element: <Outlet />,
          children: [
            { path: "", element: <RoleList /> },
            { path: "new", element: <Role /> },
            { path: ":roleId", element: <Role /> },
          ],
        },
        {
          path: "refund",
          element: <Outlet />,
          children: [
            { path: "", element: <Refund /> },
            { path: ":id", element: <TransactionView /> },
          ]
        },
        {
          path: "cm-customer",
          element: <Outlet />,
          children: [
            { path: "", element: <CMCustomerList /> },
            { path: ":id", element: <TransactionView /> },
          ]
        },
        {
          path: "report",
          element: <Outlet />,
          children: [
            { path: "", element: <ReportTabList /> }
          ]
        },
        {
          path: "charge-zone",
          element: <Outlet />,
          children: [
            { path: "", element: <ChargeZone /> }
          ]
        },
        {
          path: "management",
          element: <Outlet />,
          children: [
            { path: "", element: <Members /> },
            { path: "account-management/:id", element: <MembersManagement /> },
          ],
        },
      ],
    },

    // {
    //   path: "/course",
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: "", element: <CourseList /> },
    //     { path: ":id", element: <Course /> },
    //     { path: "content/:id", element: <CourseContent /> },
    //     { path: "content/add/:id", element: <AddContent /> },
    //   ],
    // },
    // {
    //   path: "/setup",
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/setup/usertype" replace /> },
    //     { path: "usertype", element: <UserTypeList /> },
    //     { path: "usertype/:id", element: <UserType /> },
    //     { path: "category", element: <CategoryList /> },
    //     { path: "category/:id", element: <Category /> },
    //   ],
    // },
    // {
    //   path: "/account",
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/account/basic" replace /> },
    //     { path: "basic", element: <BasicSetting /> },
    //     { path: "user", element: <UserSetting /> },
    //   ],
    // },
    {
      path: "/",
      element: !isLoggedIn ? <MainLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: "", element: <Navigate to="/login" replace /> },
        { path: "login", element: <Login /> },
        { path: "sign-up", element: <SignUp /> },
        { path: "register", element: <Register /> },
        { path: "email-verification", element: <EmailVerification /> },
        { path: "reset", element: <ResetPassword /> },
        { path: "pagenotfound", element: <NotFound /> },
      ],
    },
    { path: "*", element: <Navigate to="/pagenotfound" replace /> },
  ]);
}
