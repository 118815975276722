import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { fDateTime } from "src/utils/formatTime";

export const membersTabList = [
  { value: "accountManagement", label: "Account Management" },
];

export const memberColumns = [
  {
    field: "username",
    headerName: "Username",
    flex: 1,
    minWidth: 150,
    renderCell: ({ value, id }) => (
      <Link underline="none" component={RouterLink} to={`${id}`}>
        {value}
      </Link>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.2,
    minWidth: 250,
    renderCell: ({ value }) => (
      <Tooltip title={value} placement="top">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
    minWidth: 120,
    renderCell: ({ value }) => (
      <span>{value}</span>
    ),
  },
  {
    field: "companyName",
    headerName: "Company",
    flex: 1.2,
    minWidth: 220,
    renderCell: ({ value }) => (
      <span>{value}</span>
    ),
  },
  {
    field: "active",
    headerName: "Status",
    flex: 1,
    minWidth: 80,
    renderCell: ({ value }) => {
      let activeClass = value === "N" ? "inactive" : "active";
      let status = value === "N" ? "Inactive" : "Active";
      return (
        <span className={"status " + activeClass}>{status}</span>
      );
    },
  },
  {
    field: "lastSignedOn",
    headerName: "Last Active Time",
    type: "date",
    flex: 1,
    minWidth: 200,
    valueFormatter: ({ value }) => {
      return fDateTime(value, "yyyy-MM-dd hh:mm a");
    },
  },
];
