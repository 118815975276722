import Group from "../../image/Group.png";
import Teams from "../../image/Vector (12).png";
// import Auth from "../../image/Vector (3).png";
import Pricegroup from "../../image/nav-button-icon.png";
import User from "../../image/Vector (8).png";
import Transactions from "../../image/Vector (10).png";
import Setting from "../../image/Vector (11).png";
import ServiceRequest from "../../image/serviceRequest.svg"
import dashboardIcon from "../../image/CMS_dashboard.svg"
import walletIcon from "../../image/CMS_Wallet.svg"
import usersIcon from "../../image/CMS_Users.svg"
import transactionsIcon from "../../image/CMS_Transactions.svg"
import teamsIcon from "../../image/CMS_Teams.svg"
import settingsIcon from "../../image/CMS_Settings.svg"
import serviceRequestIcon from "../../image/CMS_Service Request.svg"
import rolesIcon from "../../image/CMS_Roles.svg"
import priceGroupIcon from "../../image/CMS_Price group.svg"
import companyIcon from "../../image/CMS_Company.svg"
import chargingProfileIcon from "../../image/CMS_Charging profile.svg"
import chargersIcon from "../../image/CMS_Chargers.svg"
import authCodesIcon from "../../image/CMS_Auth Codes.svg"
import evDriversIcon from "../../image/CMS_EV Drivers.svg"
import reportIcon from "../../image/CMS_Report.svg"
import chargeZoneIcon from "../../image/CMS_ChargeZone.svg"
import MembersIcon from "../../image/members.svg";

import { FEATURE } from "src/util/enums";
import { getIcon, getImageIcon } from "src/utils/utility";

// ----------------------------------------------------------------------

const sidebarConfig = {
  Dashboard: {
    title: "Dashboard",
    path: "/dashboard",
    icon: getImageIcon(dashboardIcon),
  },
  [FEATURE.CHARGER]: {
    title: "Charge Points",
    path: "/charger",
    icon: getImageIcon(chargersIcon),
  },
    [FEATURE.CHARGERPROFILE]:{
      title:"Charging Profile",
      path:"/chargingprofile",
      icon:getImageIcon(chargingProfileIcon)
    },
  [FEATURE.TEAM]: {
    title: "Teams",
    path: "/team",
    icon: getImageIcon(teamsIcon),
  },
  [FEATURE.TAG]: {
    title: "Resident RFID",
    path: "/tag",
    icon: getImageIcon(authCodesIcon),
  },
  [FEATURE.PRICEGROUP]: {
    title: "Price Group",
    path: "/pricegroup",
    icon: getImageIcon(priceGroupIcon),
  },
  [FEATURE.USERS]: {
    title: "CMS System User",
    path: "/users",
    icon: getImageIcon(usersIcon),
  },
  [FEATURE.TRANSACTION]: {
    title: "Transactions",
    path: "/transactions",
    icon: getImageIcon(transactionsIcon),
  },
  [FEATURE.SERVICEREQUEST]:{
    title: "Service Request",
    path:"/servicerequest",
    icon:getImageIcon(serviceRequestIcon)
  },
  [FEATURE.SETTINGS]: {
    title: "Settings",
    path: "/setting",
    icon: getImageIcon(settingsIcon),
  },
  [FEATURE.COMPANY]: {
    title: "Company",
    path: "/company",
    icon: getImageIcon(companyIcon),
  },
  [FEATURE.ROLES]: {
    title: "Roles",
    path: "/roles",
    icon: getImageIcon(rolesIcon),
  },
  [FEATURE.CUSTOMERS]: {
    title: "Residents",
    path: "/customer",
    icon: getImageIcon(evDriversIcon),
  },
  [FEATURE.REFUND]: {
    title: "Refunds",
    path: "/refund",
    icon: getImageIcon(walletIcon),
  },
  CMCustomers: {
    title: "Customer",
    path: "/cm-customer",
    icon: getImageIcon(evDriversIcon),
  },
  [FEATURE.REPORT]: {
    title: "Report",
    path: "/report",
    icon: getImageIcon(reportIcon),
  },
  [FEATURE.CHARGEZONE]: {
    title: "Charge Zone",
    path: "/charge-zone",
    icon: getImageIcon(chargeZoneIcon),
  },
  [FEATURE.MEMBERS]: {
    title: "Members",
    path: "/management",
    icon: getImageIcon(MembersIcon),
  }
  // {
  //   title: "Course Setup",
  //   path: "/course",
  //   icon: getIcon(alertTriangleFill),
  //   children: [
  //     {
  //       title: "Course",
  //       path: "/course",
  //       icon: getIcon(lockFill),
  //     },
  //     {
  //       title: "Course Store",
  //       path: "/setup/course",
  //       icon: getIcon(personAddFill),
  //     },
  //   ],
  // },
  // {
  //   title: "System Setting",
  //   path: "/setup",
  //   icon: getIcon(alertTriangleFill),
  //   children: [
  //     {
  //       title: "User Type",
  //       path: "/setup/usertype",
  //       icon: getIcon(lockFill),
  //     },
  //     {
  //       title: "Category",
  //       path: "/setup/category",
  //       icon: getIcon(personAddFill),
  //     },
  //   ],
  // },
  // {
  //   title: "Account Setting",
  //   path: "/account",
  //   icon: getIcon(alertTriangleFill),
  //   children: [
  //     {
  //       title: "Basic Setting",
  //       path: "/account/basic",
  //       icon: getIcon(lockFill),
  //     },
  //     {
  //       title: "User Setting",
  //       path: "/account/user",
  //       icon: getIcon(personAddFill),
  //     },
  //   ],
  // },
};

export default sidebarConfig;
